import React,{ useEffect, useState } from 'react';
import { Container,Row,Col } from "reactstrap";
import { matchPath } from "react-router";
import Parse from 'parse';

const SocialMedia = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/social-media/:id",
        exact: true,
        strict: false,
    });

    const schoolId = match && match.params.id;

    const logo = "https://parsefiles.back4app.com/n1BIxpDhrdEUUwxqMla37DBZoMlFIWLRVLVKXK0a/90be35e7e87d5727df3bf67be4c24a6b_Screenshot%2020240406%20at%2011.45.18%20PM.png"

    const location = "https://maps.app.goo.gl/MKH8UAMbBUVQxjFHA"
    
    const facebook = "https://www.facebook.com/KidzeePunjabiBagh/"
    const insta = "https://www.instagram.com/kidzee_punjabibagh/"
    
    const EnquiryLink = "https://forms.liltriangle.com/enquiry-form/PBe3IjTXh2"
    const website = "https://kidzeepunjabibagh.com/"

    const [schoolLinks, setSchoolLinks] = useState(undefined)
    const [loadMessage, setLoadMessage] = useState("Loading")
    
    useEffect(() => {
        if (schoolId) {

            var query = new Parse.Query("SchoolLandingPageDetails");
            if (schoolId == "PBe3IjTXh2") {
                query.equalTo("schoolId", schoolId);
            } else {
                query.equalTo("schoolKey", schoolId);
            }
            query.first().then((school) => {
                if (school) {
                    setLoadMessage("")
                    setSchoolLinks({
                        name: school.attributes.name,
                        website: school.attributes.schoolWebsite,
                        schoolLogo: school.attributes.schoolLogo,
                        linkedIn: school.attributes.linkedIn,
                        twitter: school.attributes.twitter,
                        instagram: school.attributes.instagram,
                        facebook: school.attributes.facebook,
                        location: school.attributes.location,
                        schoolId: school.attributes.schoolId
                    })
                } else {
                    setLoadMessage("Coming Soon")
                    
                }
                
            })
            
        }
    }, [schoolId]);

    return (
        <React.Fragment >
          
            {schoolLinks && <Container fluid he >

                <Row>
                    <Col>
                        <img className='rounded'/>
                        <div className="card text-center team-box">
                            <div className="card-body">
                                {schoolLinks.schoolLogo && <div>
                                    <img src={schoolLinks.schoolLogo} alt="" className="avatar-md" />
                                </div>}

                                <div className="mt-3">
                                    <h5>{schoolLinks.name}</h5>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col lg={12}>
                        {schoolLinks.facebook&&<div className="card text-center team-box">
                            <div className="card-body">
                                <div>
                                    <h3>
                                        <a href={schoolLinks.facebook} target='_blank'>
                                            <i className="m-2 bx bxl-facebook-circle" ></i>
                                            Facebook
                                        </a>
                                    </h3>
                                </div>
                            </div>
                        </div>}

                        {schoolLinks.instagram&&<div className="card text-center team-box">
                            <div className="card-body">
                                <div>
                                    <h3>
                                        <a href={schoolLinks.instagram} target='_blank'>
                                            <i className="m-2 bx bxl-instagram-alt" ></i>
                                            Instagram
                                        </a>
                                    </h3>
                                </div>
                            </div>
                        </div>}


                        {schoolLinks.location&&<div className="card text-center team-box">
                            <div className="card-body">
                                <div>
                                    <h3>
                                        
                                        <a href={schoolLinks.location} target='_blank'>
                                            <i className="m-2 bx bx-map-pin" ></i>
                                            Location
                                        </a>
                                    </h3>
                                </div>
                            </div>
                        </div>}

                        {schoolLinks.website && <div className="card text-center team-box">
                            <div className="card-body">
                                <div>
                                    <h3>
                                    
                                        <a href={schoolLinks.website} target='_blank'>
                                            <i className="m-2 bx bx-map-pin" ></i>
                                            Website
                                        </a>
                                    </h3>
                                </div>
                            </div>
                        </div>}

                        {schoolLinks.schoolId && <div className="card text-center team-box">
                            <div className="card-body">
                                <div>
                                    <h3>
                                    
                                        <a href={"https://forms.liltriangle.com/enquiry-form/"+schoolLinks.schoolId} target='_blank'>
                                            <i className="m-2 bx bx-clipboard" ></i>
                                            Enquiry Form
                                        </a>
                                    </h3>
                                </div>
                            </div>
                        </div>}
                        
                        
                    </Col>
                
                </Row>
            </Container>}

            {loadMessage && <p className='m-4 text center'>{ loadMessage}</p>}
            
        </React.Fragment>
            
    );
}
            
export default SocialMedia;